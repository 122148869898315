.topbar-container {
  /* height: 100%; */
  color: #f3f2f1;
  background-color: #007054;
  display: flex;
  flex-direction: row;

  /* gap: 35px; */
  align-items: center;
  padding: 0 20px;
  justify-content: flex-start;
}

.topbar-heading {
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 500;
  padding-right: 20px;
}

.topbar-btn {
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 200;
  background-color: #007054;
  border: none;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  padding: 20px;

  /* height: 80%; */
  align-items: center;
}

.topbar-btn > a,
.topbar-heading > a {
  text-decoration: none;
  color: #f3f2f1;
}

.topbar-img-box {
  /* height: 80%; */

  /* width: 90px; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-left: auto;
}

.topbar-btn:hover:hover {
  background-color: #005843;
}

.topbar-info-btn {
  margin-top: 3px;
  height: 50px;
  width: 50px;
  width: fit-content;
  background-color: none;
  border: none;
  justify-self: right;
}

.topbar-info-btn:hover {
  cursor: pointer;
}
