.debug-container {
  margin: 15px 15px;
  box-sizing: content;
  display: grid;
  grid-template-rows: auto 60px;

  /* max-height: 100%; */
}

.debug-code {
  display: grid;
  grid-template-columns: 40% auto;
  grid-column-gap: 15px;

  /* max-height: calc(100vh - 110px); */

  /* height: fit-content; */
}
