/* TOOLBAR  */
.debug-toolbar-title {
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-right: 2vw;
}

.debug-toolbar-panel {
  /* height: 7vh; */

  /* height: 70px; */
  height: fit-content;
  transition-duration: 0.5s;
  color: #f3f2f1;
  background-color: #007054;
  padding: 0% 3%;

  /* display: grid;
  grid-template-columns: 25% auto auto auto auto auto; */

  display: flex;
  flex-flow: row wrap;
  align-content: center;

  /* gap: 2% */

  /* box-sizing: content;
  margin: 15px 0; */

  /* margin-bottom: -3px; */

  /* border-top:  15px solid #f3f2f1; */
}

.debug-toolbar-input {
  height: 30px;
  width: 20vw;
  align-self: center;
  border: none;
  padding: 0 10px;
  background-color: #f3f2f1;
}

.debug-toolbar-input:disabled {
  background-color: #c1c1c1;
  cursor: not-allowed;
}

.debug-toolbar-input-btn {
  width: 5vw;
  height: 30px;
  align-self: center;
  color: #005843;
  background-color: #f3f2f1;
  border: none;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-weight: 200;

  /* padding: 12px 16px; */
  margin-right: 3vw;
  outline: none;
  display: flex;

  /* gap: 10px; */
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.debug-toolbar-input-btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.debug-toolbar-input-btn:hover:enabled {
  background-color: #005843;
  color: #f3f2f1;
  cursor: pointer;
}

.debug-toolbar-btn {
  background-color: #007054;
  color: #f3f2f1;
  border: none;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 200;
  padding: 12px 16px;
  outline: none;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.debug-toolbar-btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.debug-toolbar-btn:hover:enabled {
  background-color: #005843;
  cursor: pointer;
}
