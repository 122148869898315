@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

/* OVERALL  */
body {
  background-color: #f3f2f1;
  height: 100vh;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.app-view {
  height: 100%;
}

.page-view {
  color: #292a2f;
  font-family: "DM Mono", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 200;
  transition-duration: 0.2s;
  height: 100%;
}

.page-programs {
  width: 71vw;

  /* height: 100vh;
  overflow: scroll; */
  height: 100vh;
  display: grid;
  grid-template-rows: 65px auto;

  /* display: flex;
  flex-direction: column; */

  /* align-content: stretch; */
}

/* width  */
::-webkit-scrollbar {
  width: 10px;
}

/* Track  */
::-webkit-scrollbar-track {
  margin: 10px;
  border-radius: 10px;
}

/* Handle  */
::-webkit-scrollbar-thumb {
  background: #007054;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.debug-c-panel {
  background-color: #292a2f;
  margin-bottom: 15px;
  min-height: calc(100vh - 170px);
  max-height: calc(100vh - 265px);
}

.debug-c-panel-header {
  background-color: #007054;
  color: #f3f2f1;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 300;
  display: grid;
  grid-template-columns: 20% auto;
  padding: 10px 30px;
}

.debug-c-panel-title {
  margin-top: 6px;
}

.debug-c-select {
  font-size: 20px;
  background-color: #f3f2f1;
  color: #292a2f;
}

.debug-c-srcCode {
  color: #f3f2f1;
  background-color: #292a2f;
  padding: 20px;
  padding-left: 30px;
  line-height: 27px;
  max-height: calc(100vh - 265px);
  overflow: scroll;
}

.debug-asm-breakpts-active {
  height: 15px;
  width: 15px;
  background-color: none;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 6px;
  margin-left: 10px;
}

.debug-asm-breakpts-inactive {
  height: 15px;
  width: 15px;
  background-color: #dd7b79;
  border-radius: 50%;
  margin-right: 15px;
  margin-top: 6px;
  margin-left: 10px;
}

.debug-asm-breakpts-active:hover {
  background-color: rgba(221, 123, 121, 0.5);

  /* justify-self: center; */
}

.debug-asm-panel {
  background-color: #292a2f;
  margin-bottom: 15px;
  min-height: calc(100vh - 170px);
  max-height: calc(100vh - 265px);
}

.debug-asm-header {
  background-color: #007054;
  color: #f3f2f1;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 300;
  padding: 16px 30px;
}

.debug-asm-body {
  color: #f3f2f1;
  background-color: #292a2f;
  padding: 20px;

  /* padding-left: 0px; */
  line-height: 27px;
  max-height: calc(100vh - 265px);
  overflow: scroll;
}

.debug-asm-instr {
  display: grid;
  grid-template-columns: 6% 6% 37% auto;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  -webkit-justify-content: left;
          justify-content: left;
}

.debug-asm-instr-inverse {
  display: grid;
  grid-template-columns: 6% 6% 37% auto;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  -webkit-justify-content: left;
          justify-content: left;
  background-color: #f3f2f1;
  color: #292a2f;
}

.debug-asm-comment {
  justify-self: right;
}

/* TOOLBAR  */
.debug-toolbar-title {
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-right: 2vw;
}

.debug-toolbar-panel {
  /* height: 7vh; */

  /* height: 70px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition-duration: 0.5s;
  color: #f3f2f1;
  background-color: #007054;
  padding: 0% 3%;

  /* display: grid;
  grid-template-columns: 25% auto auto auto auto auto; */

  display: -webkit-flex;

  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: center;
          align-content: center;

  /* gap: 2% */

  /* box-sizing: content;
  margin: 15px 0; */

  /* margin-bottom: -3px; */

  /* border-top:  15px solid #f3f2f1; */
}

.debug-toolbar-input {
  height: 30px;
  width: 20vw;
  -webkit-align-self: center;
          align-self: center;
  border: none;
  padding: 0 10px;
  background-color: #f3f2f1;
}

.debug-toolbar-input:disabled {
  background-color: #c1c1c1;
  cursor: not-allowed;
}

.debug-toolbar-input-btn {
  width: 5vw;
  height: 30px;
  -webkit-align-self: center;
          align-self: center;
  color: #005843;
  background-color: #f3f2f1;
  border: none;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-weight: 200;

  /* padding: 12px 16px; */
  margin-right: 3vw;
  outline: none;
  display: -webkit-flex;
  display: flex;

  /* gap: 10px; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 20px;
}

.debug-toolbar-input-btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.debug-toolbar-input-btn:hover:enabled {
  background-color: #005843;
  color: #f3f2f1;
  cursor: pointer;
}

.debug-toolbar-btn {
  background-color: #007054;
  color: #f3f2f1;
  border: none;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 200;
  padding: 12px 16px;
  outline: none;
  display: -webkit-flex;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.debug-toolbar-btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.debug-toolbar-btn:hover:enabled {
  background-color: #005843;
  cursor: pointer;
}

.debug-container {
  margin: 15px 15px;
  box-sizing: content;
  display: grid;
  grid-template-rows: auto 60px;

  /* max-height: 100%; */
}

.debug-code {
  display: grid;
  grid-template-columns: 40% auto;
  grid-column-gap: 15px;

  /* max-height: calc(100vh - 110px); */

  /* height: fit-content; */
}

.topbar-container {
  /* height: 100%; */
  color: #f3f2f1;
  background-color: #007054;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;

  /* gap: 35px; */
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.topbar-heading {
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 500;
  padding-right: 20px;
}

.topbar-btn {
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 200;
  background-color: #007054;
  border: none;
  box-sizing: border-box;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 20px;

  /* height: 80%; */
  -webkit-align-items: center;
          align-items: center;
}

.topbar-btn > a,
.topbar-heading > a {
  text-decoration: none;
  color: #f3f2f1;
}

.topbar-img-box {
  /* height: 80%; */

  /* width: 90px; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-sizing: border-box;
  margin-left: auto;
}

.topbar-btn:hover:hover {
  background-color: #005843;
}

.topbar-info-btn {
  margin-top: 3px;
  height: 50px;
  width: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: none;
  border: none;
  justify-self: right;
}

.topbar-info-btn:hover {
  cursor: pointer;
}

.modal-black {
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  z-index: 1;
}

.modal-popup {
  color: #007054;
  background-color: #f3f2f1;
  width: 30vw;
  position: fixed;
  z-index: 2;
  padding: 5%;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  margin-top: 10vh;
  margin-left: 28%;
}

.modal-popupContainer {
  /* display: flex;
  justify-content: center; */
}

.modal-instructions {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.modal-closeContainer {
  display: grid;
  grid-template-columns: 90% auto;
  margin-top: -10%;
  margin-right: -15%;
}

.modal-close {
  grid-column-start: 2;
  padding: 4px;
}

.modal-close:hover {
  cursor: pointer;
}

/* memory display */
.memory-display-container {
  background-color: #292a2f;
  height: 95%;
  overflow: scroll;
  position: fixed;
  top: 0;
  right: 0;
  width: 23%;
  padding: 3%;
  line-height: 22px;
  color: #f3f2f1;
}

.memory-display-panel-header {
  font-weight: 500;
}

.memory-display-header {
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 25px;
}

.memory-display-list {
  list-style: none;
  text-indent: -40px;
}

.page-info {
  background-color: #292a2f;
  color: #f3f2f1;
  padding: 30px 40px;
  overflow: scroll;
}

.page-info-view {
  color: #292a2f;
  font-family: "DM Mono", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 200;
  transition-duration: 0.2s;
  height: 100%;
  display: grid;
  grid-template-rows: 66px auto;
}

.info-header {
  font-size: 30px;
  font-weight: 500;
}

.info-subheading {
  font-size: 22px;
  font-weight: 400;
  margin: 5px;
}

.info-desc {
  padding: 5px;
}

