@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

/* OVERALL  */
body {
  background-color: #f3f2f1;
  height: 100vh;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.app-view {
  height: 100%;
}

.page-view {
  color: #292a2f;
  font-family: "DM Mono", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 200;
  transition-duration: 0.2s;
  height: 100%;
}

.page-programs {
  width: 71vw;

  /* height: 100vh;
  overflow: scroll; */
  height: 100vh;
  display: grid;
  grid-template-rows: 65px auto;

  /* display: flex;
  flex-direction: column; */

  /* align-content: stretch; */
}

/* width  */
::-webkit-scrollbar {
  width: 10px;
}

/* Track  */
::-webkit-scrollbar-track {
  margin: 10px;
  border-radius: 10px;
}

/* Handle  */
::-webkit-scrollbar-thumb {
  background: #007054;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
