.modal-black {
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  z-index: 1;
}

.modal-popup {
  color: #007054;
  background-color: #f3f2f1;
  width: 30vw;
  position: fixed;
  z-index: 2;
  padding: 5%;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  margin-top: 10vh;
  margin-left: 28%;
}

.modal-popupContainer {
  /* display: flex;
  justify-content: center; */
}

.modal-instructions {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.modal-closeContainer {
  display: grid;
  grid-template-columns: 90% auto;
  margin-top: -10%;
  margin-right: -15%;
}

.modal-close {
  grid-column-start: 2;
  padding: 4px;
}

.modal-close:hover {
  cursor: pointer;
}
